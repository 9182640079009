
p img {
	border-color: rgb(255, 255, 255);
	border-radius: 5.52px;
	border-style: solid;
	border-width: 9px;
	border-image-outset: 0;
	border-image-repeat: stretch;
	border-image-slice: 100%;
	border-image-source: none;
	border-image-width: 1;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
	box-sizing: border-box;
	display: inline;
	margin: 0px;
	max-width: 100%;
	overflow-wrap: break-word;
	padding: 0px;
	vertical-align: baseline;
	white-space: pre-wrap;
	-webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
}
